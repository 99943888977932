import {menuEntry} from "../../../configs/menu.config";
import {cn} from "../../../utils";
import {MenuChild} from "./MenuChild";
import {MenuParent} from "./MenuParent";

interface MenuEntriesProps {
    entries: Array<menuEntry>
}

const MenuEntries = ({entries}: MenuEntriesProps) => <>
    {entries.map((entry) =>
        <li data-cy="menuEntry"
            key={`menu-entry-${entry.title}`}
            className={cn("my-1", entry.disabled && "disabled")}>
            {entry.children
                ? <MenuParent entry={entry}/>
                : <MenuChild entry={entry}/>}
        </li>
    )}
</>

export {MenuEntries}
