import axios, {AxiosError, AxiosResponse} from 'axios'
import {toast} from "react-toastify";
import {endsWith} from "lodash";

export type IPickerResponse<T> = {
    data: T,
} & TPagination

export type TPagination = {
    meta?: {
        count?: number,
        pagination?: {
            current_page: number,
            from: number,
            has_more: boolean,
            per_page: number,
            to: number,
        }
    },
    pagination?: {
        current_page: number,
        from: number,
        has_more: boolean,
        per_page: number,
        to: number,
    }
}

export const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json"
    }
})

export type PBErrorResponse = {
    code: number
    details: Array<string>
    message: string
}

const errorHandler = (error: AxiosError) => {
    const statusCode = error.response?.status

    // don't throw for missing images locally
    if (process.env.REACT_APP_ENV === 'local'
        && endsWith(error.request.responseURL, '.jpg')
    ) return Promise.reject(error)

    toast.error((error?.response?.data as PBErrorResponse).message || error.message);
    statusCode === 403 && logout()

    return Promise.reject(error)
}

const responseHandler = (data: AxiosResponse) => {
    // TODO remove handling in PBF-149
    if ((Array.isArray(data?.data?.data)
            ? data?.data?.data[0]
            : undefined
    ) === "Forbidden - Invalid token") logout()
    return data
}
const logout = () => {
    deleteCookies()
    window.location.replace('/login')
}

api.interceptors.response.use(responseHandler, errorHandler)

api.interceptors.request.use(
    function (config) {
        const type = parseCookies()?._auth_type ?? '';
        const token = parseCookies()?._auth ?? '';
        if (token) {
            config.headers.Authorization = `${type} ${token}`;
        }
        return config;
    }
);

const parseCookies = () => {
    const cookies: { [key: string]: string } = {}
    document.cookie.split('; ').forEach(cookie => {
        const parts = cookie.split('=');
        if (parts.length)
            cookies[(parts.shift() as string).trim()] = decodeURIComponent(parts.join('='));
    })
    return cookies
}

const deleteCookies = () => {
    document.cookie += "; expires=Thu, 01 Jan 1970 00:00:01 GMT"
}