import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useState} from "react";
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import {Button} from "../../../components/Actions/Button";
import {menuEntry} from "../../../configs/menu.config";
import {useMenu} from "../../../store/useMenu";
import {cn} from "../../../utils";
import {MenuChild} from "./MenuChild";
import {MenuEntries} from "./MenuEntries";
import {MenuTitle} from "./MenuTitle";

interface MenuParentProps {
    entry: menuEntry
}

const MenuParent = ({entry}: MenuParentProps) => {
    const {isCollapsed, openMenu} = useMenu()
    const navigate = useNavigate()
    const active = matchPath(entry.location || '!', useLocation()['pathname'])
    const [open, setOpen] = useState(!!active);
    const openDetails = () => {
        openMenu()
        entry.location && navigate(entry.location)
        setOpen(true)
    }

    const toggle = (e: any) => e.stopPropagation() || setOpen(!open)
    const to = (e: any) => {
        e.preventDefault()
        e.stopPropagation()
        setOpen(true)
        entry.location && navigate(entry.location)
    }

    useEffect(() => {
        isCollapsed && setOpen(false)
    }, [isCollapsed])

    return isCollapsed
        ? <MenuChild onClick={openDetails}
                     entry={entry} />
        : <details data-cy={entry.title}
                   className="rounded-lg"
                   open={open}>
            <summary data-cy="header"
                     onClick={to}
                     onFocus={e => e.target.blur()}
                     className={cn(
                         'py-1.5',
                         active && 'bg-primary text-light'
                     )}>
                <MenuTitle entry={entry} />
                <Button data-cy="collapse"
                        onClick={toggle}
                        variant="ghost"
                        className={cn(
                            active ? '' : 'bg-base-100'
                        )}
                        shape="circle"
                        size="xs">
                    <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
                </Button>
            </summary>
            <ul className="py-1 pr-2">
                <MenuEntries entries={entry.children as Array<menuEntry>} />
            </ul>
        </details>
}

export {MenuParent}