import {AxiosResponse, ResponseType} from "axios";
import {api, IPickerResponse} from "./configs/axiosConfig";
import {
    TAvatar,
    TBlacklistRequest,
    TChangeLog,
    TColumnSetRequest,
    TColumnSets,
    TColumnSetsResponse,
    TCountry,
    TCreateUniversityRequest,
    TCreateUserRequest,
    TEmployment,
    TEmploymentDocument,
    TEmploymentStepForceRequest,
    TEmploymentStepsResponse,
    TFilterResponse,
    TFilterset,
    TFiltersetsResponse,
    TGetEmploymentExtended,
    TGroupsResponse,
    TLanguage,
    TLastSearchResponse,
    TLoginRequest,
    TLoginResponse,
    TPasscode,
    TPdfTypesResponse,
    TSlot,
    TStatisticsDistributionsResponse,
    TStatisticsPlausibilityResponse,
    TStatisticsStepsResponse,
    TTrackingEvaluation,
    TTraining,
    TUnauthorized,
    TUniversitiesRequest,
    TUniversity,
    TUser,
    TUserMapped,
    TUsersColumnsResponse,
    TUsersDelete,
    TUsersGroupRequest,
    TUsersPdfsRequest,
    TUsersRequest,
    TUsersResponse,
    TZav
} from "./configs/ResponseTypes";

const blobType = 'blob' as ResponseType;
const apiRequest = (method: 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT') =>
    async <T>(url: string, data?: any, options?: {
        params?: any,
        responseType?: ResponseType,
        headers?: { [key: string]: string | undefined },
    }): Promise<T> => {
        const response: AxiosResponse<T> = await api.request({
            url,
            params: options?.params,
            headers: options?.headers,
            data,
            method,
            responseType: options?.responseType
        })

        return response.data
    }

export const API = {
    /**
     * GET
     */
    lastSearch: async () =>
        await apiRequest('GET')<IPickerResponse<TLastSearchResponse>>('/lastSearch'),
    countries: async () =>
        await apiRequest('GET')<IPickerResponse<TCountry[]>>('/countries'),
    languages: async () =>
        await apiRequest('GET')<IPickerResponse<TLanguage[]>>('/languages'),
    slots: async () =>
        await apiRequest('GET')<IPickerResponse<TSlot[]>>('/slots'),
    trainings: async () =>
        await apiRequest('GET')<IPickerResponse<TTraining[]>>('/trainings'),
    steps: async () =>
        await apiRequest('GET')<IPickerResponse<TStatisticsStepsResponse>>('/statistics/steps'),
    distributions: async () =>
        await apiRequest('GET')<IPickerResponse<TStatisticsDistributionsResponse>>('/statistics/distribution'),
    plausibility: async () =>
        await apiRequest('GET')<IPickerResponse<TStatisticsPlausibilityResponse>>('/statistics/plausibility'),
    columns: async () =>
        await apiRequest('GET')<IPickerResponse<TUsersColumnsResponse>>('/users/columns'),
    filter: async () =>
        await apiRequest('GET')<IPickerResponse<TFilterResponse>>('/filter'),
    filtersets: async () =>
        await apiRequest('GET')<IPickerResponse<TFiltersetsResponse>>('/filtersets'),
    columnsets: async () =>
        await apiRequest('GET')<IPickerResponse<TColumnSetsResponse>>('/columnsets'),
    groups: async () =>
        await apiRequest('GET')<IPickerResponse<TGroupsResponse>>('/groups'),
    pdfTypes: async () =>
        await apiRequest('GET')<IPickerResponse<TPdfTypesResponse>>('/pdf/types'),
    employmentSteps: async (id: TEmployment['id']) =>
        await apiRequest('GET')<IPickerResponse<TEmploymentStepsResponse>>(`/employment/${id}/steps`),
    passcode: async (id: TUserMapped['id']) =>
        await apiRequest('GET')<IPickerResponse<TPasscode>>(`/user/${id}/passcode`),
    changeLog: async (id: TUser['id']) =>
        await apiRequest('GET')<IPickerResponse<TChangeLog[]>>(`/user/${id}/logs`),
    employments: async (id: TUser['id']) =>
        await apiRequest('GET')<IPickerResponse<TEmployment[]>>(`/user/${id}/employments`),
    university: async (id: TUniversity['id']) =>
        await apiRequest('GET')<IPickerResponse<TUniversity>>(`/university/${id}`),
    documents: async (id: TEmployment['id']) =>
        await apiRequest('GET')<IPickerResponse<TEmploymentDocument[]>>(`/employment/${id}/documents`),
    blankDocuments: async (id: TEmployment['id']) =>
        await apiRequest('GET')<IPickerResponse<string[]>>(`/employment/${id}/documents/blank`),
    blankDocument: async (id: TEmployment['id'], type: string) =>
        await apiRequest('GET')<MediaSource>(
            `/employment/${id}/document/${type}/blank`,
            undefined,
            {responseType: blobType}
        ).then(resp => URL.createObjectURL(resp)),
    evaluation: async (date?: Date) =>
        await apiRequest('GET')<IPickerResponse<TTrackingEvaluation>>(
            `/tracking/evaluation`,
            {date: date}),
    previousZav: async (id: TEmployment['id']) =>
        await apiRequest('GET')<IPickerResponse<TZav>>(`/employment/${id}/previousZav`),
    asset: async (file: string) => await apiRequest('GET')<MediaSource>(
        `asset/${file}`,
        undefined,
        {responseType: blobType}
    ).then(resp => URL.createObjectURL(resp)),
    universityCreate: async (data: TCreateUniversityRequest) =>
        await apiRequest('POST')<undefined>('/university', data),
    editDocument: async (id: TEmploymentDocument['id'], file: File, type: string) => {
        const formData = new FormData();
        formData.append('file', file);

        return await apiRequest('POST')<IPickerResponse<TEmploymentDocument>>(
            `/employment/${id}/document/${type}`,
            formData,
            {headers: {'Content-Type': undefined}}
        )
    },
    applicationReview: async (id: TEmployment['id']) =>
        await apiRequest('GET')<IPickerResponse<string[]>>(`/employment/${id}/step/applicationReview`),
    documentsReview: async (id: TEmployment['id']) =>
        await apiRequest('GET')<IPickerResponse<string[]>>(`/employment/${id}/step/documentsReview`),
    employ: async (id: TEmployment['id']) =>
        await apiRequest('GET')<IPickerResponse<string[]>>(`/employment/${id}/step/employ`),
    settleUp: async (id: TEmployment['id']) =>
        await apiRequest('GET')<IPickerResponse<string[]>>(`/employment/${id}/settleUp`),
    unsettle: async (id: TEmployment['id']) =>
        await apiRequest('GET')<IPickerResponse<string[]>>(`/employment/${id}/unsettle`),

    /**
     * POST
     */
    login: async (data: TLoginRequest) =>
        await apiRequest('POST')<IPickerResponse<TLoginResponse>>('/login', data),
    users: async (data: TUsersRequest) =>
        await apiRequest('POST')<IPickerResponse<TUsersResponse>>('/users', data),
    export: async (data: TUsersRequest) =>
        await apiRequest('POST')<Blob>('/users/excel', data, {responseType: "blob"})
            .then(blob => downloadFile(blob, 'UserExcelExport.xlsx')),
    createUser: async (data: TCreateUserRequest) =>
        await apiRequest('POST')<IPickerResponse<TUser>>('/appuser', data),
    universities: async (data: TUniversitiesRequest) =>
        await apiRequest('POST')<IPickerResponse<Array<TUniversity>>>('/universities', data),
    saveFilterset: async (data: Omit<TFilterset, 'id'>) =>
        await apiRequest('POST')<IPickerResponse<TFilterset>>('/filterset', data),
    columnset: async (data: TColumnSetRequest) =>
        await apiRequest('POST')<IPickerResponse<TColumnSets>>('/columnset', data),
    blacklist: async (data: TBlacklistRequest) =>
        await apiRequest('POST')<undefined>(`/users/blacklist`, data),
    usersGroup: async (data: TUsersGroupRequest) =>
        await apiRequest('POST')<undefined>('/users/group', data),
    usersDelete: async (data: TUsersDelete) =>
        await apiRequest('POST')<undefined>('/users/delete', data),
    employmentExtended: async (data: TGetEmploymentExtended) =>
        await apiRequest('POST')<IPickerResponse<any>>(`/employment/extended`, data),
    newEmployment: async (id: TUser['id']) =>
        await apiRequest('POST')<IPickerResponse<TEmployment>>(`/employment/${id}/newApplication`),
    usersPdfs: async (data: TUsersPdfsRequest) =>
        await apiRequest('POST')<Blob>('/users/pdfs', data, {responseType: 'blob'})
            .then(blob => downloadFile(blob, 'documents.zip')),
    avatar: async (id: TUser['id'], file: File) => {
        const formData = new FormData();
        formData.append('avatar', file);

        return await apiRequest('POST')<IPickerResponse<TAvatar>>(
            `/user/${id}/avatar`,
            formData,
            {headers: {'Content-Type': undefined}}
        )
    },


    /**
     * PATCH
     */
    employmentStepForce: async (id: TEmployment['id'], data: TEmploymentStepForceRequest) =>
        await apiRequest('PATCH')<undefined>(`/employment/${id}/step/force`, data),
    updateEmployment: async (id: TEmployment['id'], data: object) =>
        await apiRequest('PATCH')<undefined>(`/employment/${id}`, data),
    updateUniversity: async (id: TUniversity['id'], data: Partial<TUniversity>) =>
        await apiRequest('PATCH')<IPickerResponse<TUniversity>>(`/university/${id}`, data),

    /**
     * DELETE
     */
    delete: {
        filterset: async (id: TFilterset['id']) =>
            await apiRequest('DELETE')<undefined>(`/filterset/${id}`),
        columnset: async (id: TColumnSets['id']) =>
            await apiRequest('DELETE')<undefined>(`/columnset/${id}`),
        document: async (id: TEmploymentDocument['id']) =>
            await apiRequest('DELETE')<undefined>(`/document/${id}`)
    },
}

export function isUnauthorized(response: any):
    response is TUnauthorized {
    return Array.isArray(response)
        && response.length === 1
        && response[0] === 'Unauthorized.';
}

const downloadFile = (blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
};
