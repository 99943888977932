import {faInfo, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {useSearch} from "../../store/useSearch";
import {Tooltip} from "../DataDisplay/Tooltip";
import {TextInput, TextInputProps} from "../DataInput/TextInput";
import {Prefix, Suffix} from "../utils/Slots";

interface SearchProps extends TextInputProps {
    info?: string,
    queryKey?: string,
    onEnter?: (searchString: string) => void
}

const Search = React.forwardRef<HTMLInputElement, SearchProps>((
    {
        placeholder,
        info,
        queryKey = 'q',
        value,
        onEnter,
        id,
        ...props
    },
    ref
) => {
    const {t} = useTranslation()
    id ||= _.uniqueId('search-')
    const search = useSearch()
    const [searchValue, setSearchValue] = useState(value as string || '');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value)
    }

    useEffect(() => {
        setSearchValue(value as string ?? '')
    }, [value]);

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            search.set(queryKey, searchValue);
            if (onEnter) {
                onEnter(searchValue as string)
            }
            setSearchValue('');
            (document.activeElement as HTMLInputElement).blur()
        }
    }

    return <TextInput data-cy="search"
                      placeholder={placeholder || t('searching')}
                      id={id}
                      ref={ref}
                      value={searchValue}
                      onChange={handleChange}
                      onKeyDownCapture={handleEnter}
                      {...props}>
        <Prefix>
            <label htmlFor={id}
                   className="cursor-text">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
            </label>
        </Prefix>
        {info && <Suffix>
            <Tooltip tip={info}
                     position="right">
                <span className="info bg-base-100">
                    <FontAwesomeIcon icon={faInfo} />
                </span>
            </Tooltip>
        </Suffix>}
    </TextInput>
})

export {Search}