import {IconDefinition} from "@fortawesome/free-regular-svg-icons";
import {
    faBook,
    faGear,
    faGraduationCap,
    faHome,
    faNewspaper,
    faRoute,
    faTachographDigital,
    faUserGroup,
    faUsers,
    faWandMagicSparkles
} from '@fortawesome/free-solid-svg-icons'

export interface menuEntry {
    title: string,
    icon: IconDefinition,
    location?: string,
    disabled?: boolean,
    children?: Array<menuEntry>,
    filter?: [{ name: string, values: Array<string | boolean> }]
}

const menuConfig = (): Array<menuEntry> => [
    {
        title: 'home',
        icon: faHome,
        location: '/',
        disabled: false,
    },
    {
        title: 'userList',
        icon: faUsers,
        location: '/users',
        disabled: false,
        // as example:
        // children: [
        // {
        //     title: 'blacklist',
        //     icon: faBan,
        //     location: '/users',
        //     filter: [{name: 'blacklist', values: [true]}],
        //     disabled: false,
        // }
        // ]
    },
    {
        title: 'travel',
        icon: faRoute,
        location: '/reisen',
        disabled: true
    },
    {
        title: 'news',
        icon: faNewspaper,
        location: '/news',
        disabled: true
    },
    {
        title: 'settings',
        icon: faGear,
        disabled: false,
        children: [
            {
                title: 'management',
                icon: faUserGroup,
                location: '/settings/admin',
                disabled: true
            },
            {
                title: 'fibel',
                icon: faBook,
                location: '/settings/manual',
                disabled: true
            },
            {
                title: 'universities',
                icon: faGraduationCap,
                location: '/universities',
                disabled: false,
            },
            {
                title: 'functions',
                icon: faWandMagicSparkles,
                location: '/settings/functions',
                disabled: true
            }
        ]
    },
    {
        title: 'tracking',
        icon: faTachographDigital,
        location: '/tracking',
        disabled: false
    },
]

export default menuConfig