import _ from "lodash";
import {create} from 'zustand'
import {TFilter, TFilterset} from "../api/configs/ResponseTypes";
import {API, isUnauthorized} from "../api/API";
import {isObject} from "../utils";

export type TFilterState = {
    isFilterOverlayOpen: boolean
    selectedFilter: TFilterset['filters']
    appliedFilter: TFilterset['filters']
    isLoading: boolean
}

type TFilterActions = {
    allFilter: () => Array<TFilter>
    setAllFilter: (filter: Array<TFilter>) => void
    setFilterOverlay: (isFilterOverlayOpen: TFilterState['isFilterOverlayOpen']) => void
    toggleFilterOverlay: () => void
    selectFilter: (filter: TFilterState['selectedFilter'] | undefined | null) => void
    applyFilter: () => void
    addFilter: (id: string, value: string | Array<string | boolean> | boolean | object) => void
    removeFilter: (id: string, value?: string | boolean | object) => void
    isFilterSelected: (id: string, value: string | boolean | object) => boolean
    getFilter: (name: string | Array<string>) => Array<TFilter> | null
    reset: () => void,
    loadFilter: () => void
}

const initialState: TFilterState = {
    isFilterOverlayOpen: false,
    appliedFilter: [],
    selectedFilter: [],
    isLoading: false,
}

export const useFilter = create<TFilterState & TFilterActions>((set, get) => {
    let _allFilter: Array<TFilter> = []
    let _nextTimeout: string = new Date(0).toISOString()
    let _retries: number = 0
    let _retrying: boolean = false
    return {
        ...initialState,
        allFilter: () => {
            _allFilter?.length || get().loadFilter()
            return _allFilter
        },
        setAllFilter: (filter) => _allFilter = filter,
        setFilterOverlay: (isFilterOverlayOpen) => set({
            isFilterOverlayOpen,
            selectedFilter: _.cloneDeep(get().appliedFilter)
        }),
        toggleFilterOverlay: () => set({
            isFilterOverlayOpen: !get().isFilterOverlayOpen,
            selectedFilter: _.cloneDeep(get().appliedFilter)
        }),
        selectFilter: (filter) => {
            set({selectedFilter: Array.isArray(filter) ? filter : []})
        },
        applyFilter: () => set({
            appliedFilter: _.cloneDeep(get().selectedFilter),
        }),
        addFilter: (id, value) => {
            const old = _.cloneDeep(get().selectedFilter)
            const values = Array.isArray(value) ? value : [value]
            const existingFilter = old.find(o => o.id === id)
            if (existingFilter) {
                existingFilter.values = isObject(value)
                    ? _.unionWith(existingFilter?.values, values, _.isEqual)
                    : _.union(existingFilter.values, values);
            } else {
                old.push({id, values})
            }
            set({selectedFilter: old})
        },
        removeFilter: (id, value) => {
            const old = _.cloneDeep(get().selectedFilter)
            if (value) {
                old.find(o => o.id === id)?.values.forEach(
                    (val, i, values) => _.isEqual(val, value) && values.splice(i, 1)
                )
                set({selectedFilter: old.filter(out => out.values.length > 0)})
            } else {
                set({selectedFilter: old.filter(filter => filter.id !== id)})
            }
        },
        isFilterSelected: (id, value) => {
            return get().selectedFilter
                .find(f => f.id === id)
                ?.values.find(v => JSON.stringify(v) === JSON.stringify(value)) !== undefined;
        },
        getFilter: (name) => {
            const names = Array.isArray(name) ? name : [name];
            return _allFilter.filter(f => names.includes(f.name)) ?? null;
        },
        reset: () => set({..._.cloneDeep(initialState)}),
        loadFilter: () => {
            const nowStr = new Date().toISOString();
            if (nowStr <= _nextTimeout && !_retrying) {
                _retrying = true
                setTimeout(get().loadFilter,
                    Math.abs((new Date().getTime() - new Date(_nextTimeout).getTime()) / 1000)
                );
            } else if (nowStr > _nextTimeout && !_allFilter?.length && !get().isLoading) {
                set({isLoading: true});
                API.filter()
                    .then(({data}) => {
                        isUnauthorized(data) || (_allFilter = data);
                        _retries = 0
                    })
                    .catch(() => {
                        _retries += 1
                    })
                    .finally(() => {
                        const date = new Date();
                        date.setSeconds(date.getSeconds() + 2 ** _retries);
                        _nextTimeout = date.toISOString()
                        _retrying = false
                        set({
                            isLoading: false,
                        });
                    });
            }
        }
    };
})

useFilter.getState().loadFilter()