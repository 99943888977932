import {NavLink} from "../../../components/Navigation/NavLink";
import {menuEntry} from "../../../configs/menu.config";
import {cn} from "../../../utils";
import {MenuTitle} from "./MenuTitle";
import useUrlBuilder from "../../../utils/useUrlBuilder";
import {useEffect, useState} from "react";

interface MenuChildProps {
    entry: menuEntry
    onClick?: () => void,
    'data-cy'?: string
}

const MenuChild = ({entry, onClick}: MenuChildProps) => {
    const {updateUrl} = useUrlBuilder();
    const [url, setUrl] = useState(entry.location ?? '')

    useEffect(() => {
        entry?.filter?.length && updateUrl(entry.location ?? '', entry.filter, setUrl)
    }, []);

    return <NavLink data-cy={entry.title}
                    to={url}
                    onClick={onClick}
                    disabled={entry.disabled}
                    onFocus={e => e.target.blur()}
                    className={({isActive}) => cn(
                        !entry?.filter && isActive && "bg-primary text-light"
                    )}>
        <MenuTitle entry={entry}/>
    </NavLink>
}

export {MenuChild}