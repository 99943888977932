import menuConfig from "../../../configs/menu.config";
import {MenuEntries} from "./MenuEntries";

const MenuElements = () => {
    return <ul data-cy="menuElements"
               className="px-0 transition-all menu">
        <MenuEntries entries={menuConfig()}/>
    </ul>
}

export {MenuElements}