import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {menuEntry} from "../../../configs/menu.config";
import {useMenu} from "../../../store/useMenu";
import {useTranslation} from "react-i18next";

interface MenuTitleProps {
    entry: menuEntry
}

const MenuTitle = ({entry}: MenuTitleProps) => {
    const {isCollapsed} = useMenu()
    const {t} = useTranslation()

    return <>
        <FontAwesomeIcon className="h-5 w-5"
                         icon={entry.icon} />
        {isCollapsed || t(entry.title)}
    </>
}

export {MenuTitle}