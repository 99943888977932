import {ClassValue, clsx} from "clsx";
import {twMerge} from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs))
}

export function nameShortener(fullname: string, max: number) {
    return fullname.length > max ? fullname.split(' ')
        .map((v, i, a) => {
            return i < a.length - 1 ? v[0]?.toUpperCase() : v
        })
        .join('. ') : fullname
}

export function shorten(str: any, length: number = 60): string {
    if (!str || typeof str !== "string" || str.length <= length) {
        return str;
    }
    return str.substring(0, length) + '...';
}

export function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

export function isObject(item: any): boolean {
    return item && typeof item === 'object' && !Array.isArray(item);
}

export function deepMerge(target: any, source: any): any {
    if (!isObject(target) || !isObject(source)) {
        return source;
    }

    const output = {...target};

    Object.keys(source).forEach(key => {
        output[key] = isObject(source[key]) && (key in target)
            ? deepMerge(target[key], source[key])
            : source[key]
    });

    return output;
}

// resolver for factories
export const resolve = (val: any, fallback: any) =>
    (typeof val === 'function') ? val() : (val ?? fallback)

export function strUntil(input: string, searchString: string) {
    const index = input.lastIndexOf(searchString);
    return index !== -1 ? input.substring(0, index) : input;
}

export function seedStrToRnd(seed: string) {
    return (
        Array.from(seed).reduce((hash, char) => hash + char.charCodeAt(0) / 128, 0) % 1
    );
}

export function actualSeason()
{
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();

    return month >= 9 ? year + 1 : year;
}