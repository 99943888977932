import {create} from 'zustand'

type TSearchState = {
    urlSearchParams: URLSearchParams
    updated: Date
}

type TSearchActions = Pick<URLSearchParams, 'append' | 'has' | 'get' | 'getAll' | 'set' | 'values' | 'delete'> & {
    init: (params: string) => void
    reset: () => void
}

const initialState: TSearchState = {
    urlSearchParams: new URLSearchParams(),
    updated: new Date()
}

const update = (set: (partial: Partial<TSearchState>) => void, get: () => TSearchState & TSearchActions) => {
    for (const [key, value] of get().urlSearchParams.entries()) {
        !value && get().urlSearchParams.delete(key)
    }
    set({updated: new Date()})
}

export const useSearch = create<TSearchState & TSearchActions>((set, get) => ({
    ...initialState,
    init: (params) => set({urlSearchParams: new URLSearchParams(params), updated: new Date()}),
    append: (name, value) => {
        const params = get().urlSearchParams;

        if (params.has(name)) {
            const existingValue = params.get(name);
            params.set(name, `${existingValue},${value}`);
        } else {
            params.append(name, value);
        }
        update(set, get)
    },
    has: (name) => get().urlSearchParams.has(name),
    get: (name) => get().urlSearchParams.get(name),
    getAll: (name) => get().urlSearchParams.getAll(name),
    set: (name, value) => {
        get().urlSearchParams.set(name, value)
        update(set, get)
    },
    delete: (name) => {
        get().urlSearchParams.delete(name)
        update(set, get)
    },
    values: () => get().urlSearchParams.values(),
    reset: () => set({
        ...initialState,
        updated: new Date(),
        urlSearchParams: new URLSearchParams()
    }),
    toString: () => "?" + get().urlSearchParams.toString()
}))